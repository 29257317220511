import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "AgentUserListView"
};
import TableXsr from "@/components/Table/Table-User";
import { onMounted, ref } from "vue";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Apis_Agent from "@/apis/Apis_Agent";
import CacheData from "@/utils/Cache/CacheData";
import Common_Router from "@/utils/Common/Common_Router";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentUserList',
  props: ['UserName'],
  setup(__props) {
    const props = __props;
    const LangLib = new LangCom('Agent', 'AgentUserList');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const actions = [{
      name: GetLangSetup('actions.1'),
      type: 1
    }];
    const show = ref(false);
    const onSelect = item => {
      show.value = false;
      console.log('onSelect', item);
      if (item.type === 1) {
        Common_Router.push({
          name: 'Agent.AgentUserList-FetchUser'
        });
      }
    };

    //获取用户信息代码块
    const userListArr = ref([]);
    const userNameAndIdArr = ref([]); //[数组对象]每个对象包含(用户名与用户id)
    const getUserList = () => {
      Apis_Agent.getAgentUserList(props.UserName).then(res => {
        var _res$data;
        if (!Apis_Agent.checkResCode(res)) {
          return;
        }
        userListArr.value = (_res$data = res.data) !== null && _res$data !== void 0 ? _res$data : [];
        userNameAndIdArr.value = [];
        for (let dataKey in res.data) {
          var _res$data$dataKey$id, _res$data$dataKey$use;
          userNameAndIdArr.value.push({
            id: (_res$data$dataKey$id = res.data[dataKey].id) !== null && _res$data$dataKey$id !== void 0 ? _res$data$dataKey$id : -1,
            username: (_res$data$dataKey$use = res.data[dataKey].username) !== null && _res$data$dataKey$use !== void 0 ? _res$data$dataKey$use : ''
          });
        }
        VantMsgLib.notify(2, GetLangSetup('getUserList.tips') + res.data.length);
      });
    };
    onMounted(() => {
      setTimeout(() => {
        getUserList();
      }, 0);
    });
    const TableEvent = options => {
      // console.log('TableEvent',options);
      // [事件类型]1.工具栏 2.选中用户变动 3.点击某个表项 4.点击了放大镜图标[筛选/搜索]
      if (options.type === 1) {
        show.value = true;
      } else if (options.type === 2) {
        //生成通用列表数据
        CacheData.SaveData('AgentUserList-FetchUser', options.data);
        CacheData.SaveData('AgentUserList-Agent', props.UserName);
        VantMsgLib.alertSuccess();
      }
    };
    return (_ctx, _cache) => {
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TableXsr), {
        ViewData: userNameAndIdArr.value,
        HeaderTitle: GetLangTemplate('HeaderTitle'),
        onTableEvent: TableEvent
      }, null, 8, ["ViewData", "HeaderTitle"]), _createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => show.value = $event),
        actions: actions,
        onSelect: onSelect
      }, null, 8, ["show"])]);
    };
  }
};